import { FC } from 'react';
import { NonInteractiveActionBaseProps } from '../ActionBaseProps';
import ActionTitleDescription from '../ActionTitleDescription';

const DescriptionAction: FC<NonInteractiveActionBaseProps> = (props) => {
  const { data } = props;

  return (
    <div data-cy="description-action">
      <ActionTitleDescription {...data} />
    </div>
  );
};

export default DescriptionAction;
